import React from 'react';

import {
    AllArtworksHeading,
    ArtworkWrapper,
    ArtworkContainer,
    AllArtworksContainer,
    ArtworkImageHolder,
    ArtworkArtist,
    ArtworkTitle,
} from './styledElements';

const AllArtworks = ({ artworks, assetsPath, cdnPath, artworkSlugs, language }) => {
    // const getUrl = (id, external_link) => {
    //     if (external_link && external_link !== 'x') {
    //         return external_link;
    //     }
    //     return `/${language}/${artworkSlugs[id]}`;
    // };

    const getUrl = (id) => {
        // return `/${language}/${artworkSlugs[id]}`;
        return `/${artworkSlugs[id]}`;
    };

    return <>
        <AllArtworksHeading id="all-artworks">
            {'All artworks'}
        </AllArtworksHeading>
        <AllArtworksContainer>
            {artworks.map(({
                   image_file_name,
                   id,
                   artwork_title,
                   artist,
                   external_link,
               }) => (
                    <ArtworkContainer key={id} visible>
                        <a href={getUrl(id, external_link)}>
                            <ArtworkWrapper>
                                <ArtworkImageHolder>
                                    <img src={`${cdnPath}/artworks/thumbnails/${image_file_name.split(';')[0]}`} alt="preview"/>
                                </ArtworkImageHolder>
                                <div>
                                    <ArtworkArtist>{artist}</ArtworkArtist>
                                    <ArtworkTitle>{artwork_title}</ArtworkTitle>
                                </div>
                            </ArtworkWrapper>
                        </a>
                    </ArtworkContainer>
                )
            )}
        </AllArtworksContainer>
    </>
};

export default AllArtworks;
