import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Visualizations from "./EntryContent/Visualizations";
import AllArtworks from "./EntryContent/AllArtworks";
import Header from "./Header";
import Footer from './Footer';
import Layout from './Layout';
import {
    ExhibitionFold,
    VideoDescription,
    VideoSection,
    ExhibitionFoldContent,
    Content
} from './EntryContent/styledElements';
import Video from "./EntryContent/Video";
import { ArtworkInfoButton, ArtworkInfoButtonContainer} from "./ArtworkContent/styledElements";

const PastEntry = ({
  artworks,
  slug,
  assetsPath,
  cdnPath,
  exhibition,
  visualizations,
  language,
  artworkSlugs,
  artistSlug,
}) =>  (
    <Layout imageBgUrl={`${assetsPath}/home/${exhibition.home_page_image_file_name}`}>
      <Content>
        <Header assetsPath={assetsPath}/>
      </Content>
        <ExhibitionFold background={assetsPath + '/home/'+ exhibition.home_page_image_file_name}>
            <ExhibitionFoldContent>
                <h1>{exhibition.title}</h1>
                <h2>{exhibition.subtitle}</h2>
                <p>{exhibition.description}</p>
            </ExhibitionFoldContent>
        </ExhibitionFold>
        <Content>
          <VideoSection>
              <VideoDescription>
                  <h1>About the exhibition</h1>
                  <p>{exhibition.home_page_info}</p>
                  <ArtworkInfoButtonContainer>
                  <ArtworkInfoButton
                      as="a"
                      href={`${assetsPath}/documents/${exhibition.press_file_name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="primary"
                      size="short"
                      centered
                  >Press</ArtworkInfoButton>
                  <ArtworkInfoButton
                      type="secondary"
                      onClick={() => scrollTo(`#all-artworks`)}
                  >Browse catalogue</ArtworkInfoButton>
                  </ArtworkInfoButtonContainer>
              </VideoDescription>
              <div>
                  <Video
                      videoUrl={exhibition.video_url}
                  />
              </div>
          </VideoSection>

        <Visualizations
          visualizations={visualizations}
          artworks={artworks}
          slug={slug}
          artistSlug={artistSlug}
          assetsPath={assetsPath}
          cdnPath={`${cdnPath}/${exhibition.assets_folder}`}
          fillColor="rgba(255, 255, 255, 0.3)"
          strokeColor="rgba(255, 255, 255, 1)"
          language={language}
          hasSlider
        />
        <AllArtworks
          artworks={artworks}
          assetsPath={assetsPath}
          cdnPath={`${cdnPath}/${exhibition.assets_folder}`}
          artworkSlugs={artworkSlugs}
          language={language}
        />
        <Footer
            exhibition={exhibition}
            assetsPath={assetsPath}
            cdnPath={`${cdnPath}/${exhibition.assets_folder}`}
        />
      </Content>
    </Layout>
  );

export default PastEntry;
